import request from './request';
import common from "./common";

// 申办方 登录
export function getLogin(data) {
  return request({
    url: "/login",
    method: "post",
    data
  })
}

// 登录发送验证码
export function sendRoleVerificationCode(data) {
  return common({
    url: "/RoleVerificationCode",
    method: "post",
    data
  })
}

// 根据访视申请 ID 查询 费用明细+流程状态信息
export function getFeeRequestDetail(data) {
  return common({
    url: "/getExpenseDetails",
    method: "post",
    data
  })
}

// 申办方 修改密码
export function getChangPwd(data) {
  return request({
    url: "/changePwd",
    method: "post",
    data
  })
}

// 根据登录人ID 查询项目列表 select
export function getProjectByUserId(data) {
  return request({
    url: "/getSpProjectList",
    method: "post",
    data
  })
}

// 获取访视费用申请列表
export function getCostList(data) {
  return request({
    url: "/getCompensationList",
    method: "post",
    data
  })
}

// 获取访视费用申请明细
export function getCostDetail(data) {
  return request({
    url: "/getCompensationDetails",
    method: "post",
    data
  })
}

// 访视费用申请  审核
export function getSponsorVerify(data) {
  return request({
    url: "/sponsorVerify",
    method: "post",
    data
  })
}

