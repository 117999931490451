<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="apply-audit-dialog">
  <div class="dialog-body" v-loading="loading">
    <div class="aad-title">申请信息</div>
    <table class="aad-base-info-table my-table">
      <tr>
        <th>所属项目</th>
        <td>{{compensationDetails.txt_project_name}}</td>
        <th>所属中心</th>
        <td>{{compensationDetails.txt_hospital_name}}</td>
      </tr>
      <tr>
        <th>所属研究者</th>
        <td>{{compensationDetails.txt_researcher_name}}</td>
        <th>访视阶段</th>
        <td>{{compensationDetails.txt_interview_name}}</td>
      </tr>
      <tr>
        <th>费用金额</th>
        <td>{{compensationDetails.dec_compensation_total}}元</td>
        <th>申请时间</th>
        <td>{{compensationDetails.dt_researcher_verify||"- -"}}</td>
      </tr>
    </table>
    <div class="aad-title has-margin-top">受试者信息</div>
    <table class="aad-base-info-table my-table">
      <tr>
        <th>筛选号</th>
        <td>{{compensationDetails.txt_filter_number}}</td>
        <th>姓名缩写</th>
        <td>{{compensationDetails.txt_examinee_spell}}</td>
      </tr>
      <tr>
        <th>性别</th>
        <td>{{compensationDetails.txt_examinee_gender}}</td>
        <th>年龄</th>
        <td>{{compensationDetails.int_examinee_age}}</td>
      </tr>
    </table>
    <div class="aad-title has-margin-top row-center-start has-pointer">
      <div :class="tabType=='fee'?'is-actived':''" @click="tabType='fee'">费用明细</div>
      <template v-if="dialogData.type == 'detail'">
        <span></span>
        <div :class="tabType=='flow'?'is-actived':''" @click="getTimeLineData()">审批流程</div>
      </template>
    </div>
    <div class="cost-detail-list-table" :class="tabType=='flow'?'has-flex':''">
      <template v-if="tabType=='fee'">
        <div class="cdlt-item" v-for="(item, index) in expenseDetails" :key="index">
          <div class="cdlt-item-line">
            <div class="cdltil-title">费用类型</div>
            <div class="cdltil-content">{{item.txt_compensation_type_name}}</div>
          </div>
          <div class="cdlt-item-line">
            <div class="cdltil-title">申报金额</div>
            <div class="cdltil-content">{{item.dec_compensation_amount}}元</div>
          </div>
          <div class="cdlt-item-line">
            <div class="cdltil-title">申报明细</div>
            <div class="cdltil-content">{{item.txt_compensation_item_desc}}</div>
          </div>
          <div class="cdlt-img-line" v-if="(item.imgList||[]).length>0">
            <div class="cdltil-title">凭证</div>
            <!-- <div class="cdltil-content-imgs">
            <el-image v-for="(sItem, sIndex) in item.imgList" :key="sIndex" :src="sItem" :preview-src-list="item.imgList" fit="cover" />
          </div> -->
            <div class="cdltil-content-imgs">
              <div class="cdltilci-item" v-for="(sItem, sIndex) in item.imgList" :key="sIndex">
                <template v-if="judgeFormat(sItem)">
                  <el-image :src="sItem" :preview-src-list="viewImg(item.imgList)" fit="cover" />
                </template>
                <template v-else>
                  <div class="file-part" @click="viewFile(sItem)">
                    <el-icon>
                      <Document />
                    </el-icon>
                    {{ getFileName(sItem) }}
                  </div>
                </template>
              </div>
              <!-- <el-image :src="sItem" :preview-src-list="item.imgList" fit="cover" /> -->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <el-timeline>
          <el-timeline-item v-for="(tItem, index) in timeLine" :key="index" :hide-timestamp="tItem.key==null" :timestamp="timeLineDetail[tItem.key] || '- -'" :color="tItem.key==null?'#e4e7ed':'#9c9bdb'">
            {{ tItem.title }}
            <p v-if="tItem.remark">（驳回原因：{{ timeLineDetail[tItem.remark] }}）</p>
          </el-timeline-item>
        </el-timeline>
      </template>
    </div>
  </div>
  <template v-if="dialogData.type !='detail'" #footer>
    <div class="dialog-footer">
      <el-button color="#8a88c3" @click="audit(true)" type="primary">同 意</el-button>
      <el-button @click="audit(false)" type="info">拒 绝</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 审核组件
// "status": 1, 状态 1 同意 2 拒绝
import { onMounted, reactive, toRefs } from "vue";
import { Document } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from 'element-plus/lib/components';
import { getCostDetail, getSponsorVerify, getFeeRequestDetail } from "api/apis.js";
export default {
  props: {
    dialogData: { type: Object },
  },
  components: {
    Document,
  },
  setup(prop, ctx) {
    const state = reactive({
      visible: true,
      picsData: [],
      loading: false,
      txt_approval_number: null,
      compensationDetails: {},
      expenseDetails: [],
      title: "审核",
      dialogData: {},
      timeLine: [],
      timeLineDetail: {},
      baseLine: [{
          value: null,
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "等待研究者审核", key: null }
          ]
        },
        {
          value: "-1",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "取消申请", key: "dt_update" }
          ]
        },
        {
          value: "1",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "等待下一级审核", key: null }
          ]
        },
        {
          value: "3",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者驳回", key: "dt_researcher_verify", remark: "txt_researcher_verify_remarks" }
          ]
        },
        {
          value: "6",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "CRA审核完成", key: "dt_cra_verify" },
            { title: "等待申办方审核", key: null }
          ]
        },
        {
          value: "7",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "CRA驳回", key: "dt_cra_verify", remark: "txt_cra_verify_remarks" }
          ]
        },
        // 截断
        {
          value: "2",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "申办方审核完成", key: "dt_sponsor_verify" },
            { title: "等待后台发放", key: null }
          ]
        },
        {
          value: "4",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "申办方驳回", key: "dt_sponsor_verify", remark: "txt_sponsor_verify_remarks" }
          ]
        },
        {
          value: "5",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "申办方审核完成", key: "dt_sponsor_verify" },
            { title: "发放完成", key: "dt_update" }
          ]
        },
        {
          value: "62",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "CRA审核完成", key: "dt_cra_verify" },
            { title: "申办方审核完成", key: "dt_sponsor_verify" },
            { title: "等待后台发放", key: null }
          ]
        },
        {
          value: "64",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "CRA审核完成", key: "dt_cra_verify" },
            { title: "申办方驳回", key: "dt_sponsor_verify", remark: "txt_sponsor_verify_remarks" }
          ]
        },
        {
          value: "65",
          text: [
            { title: "发起申请", key: "dt_compensation_application" },
            { title: "研究者审核完成", key: "dt_researcher_verify" },
            { title: "CRA审核完成", key: "dt_cra_verify" },
            { title: "申办方审核完成", key: "dt_sponsor_verify" },
            { title: "发放完成", key: "dt_update" }
          ]
        }
      ],
      tabType: "fee"
    })

    onMounted(() => {
      state.dialogData = prop.dialogData;
      if (prop.dialogData.type == "detail") {
        let txt_grant_status;
        switch (prop.dialogData.txt_grant_status) {
          case "0":
            txt_grant_status = "待审核";
            break;
          case "-1":
            txt_grant_status = "已取消";
            break;
          case "1":
            txt_grant_status = "审核中";
            break;
          case "2":
            txt_grant_status = "已审核";
            break;
          case "3":
            txt_grant_status = "已驳回";
            break;
          case "4":
            txt_grant_status = "已发放";
        }
        state.title = "详情" + " - " + txt_grant_status;
      }
      getAuditDetail();
    })

    // 格式化文件名
    const getFileName = iItem => {
      let fileName = iItem.split("?")[0];
      return fileName.split(".com/")[1];
    };

    // 判断文件类型 图片/文件
    const judgeFormat = sItem => {
      let judge = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"],
        endWith = sItem.split("?")[0].split("com/")[1].split(".")[1];
      return judge.findIndex(t => t == endWith) != -1;
    };

    // 预览图片
    const viewImg = imgList => {
      let urls = [],
        judge = ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"];
      for (let i = 0; i < imgList.length; i++) {
        let endWith = imgList[i].split("?")[0].split("com/")[1].split(".")[1];
        if (judge.findIndex(t => t == endWith) != -1) {
          urls.push(imgList[i]);
        }
      }
      return urls;
    };

    // 预览文件
    const viewFile = sItem => {
      window.open(sItem);
    };

    // 获取流程信息
    const getTimeLineData = () => {
      state.tabType = 'flow';
      getFeeRequestDetail({
        t_compensation_application_id: prop.dialogData.application_id
      }).then(response => {
        if (response.code == 200) {
          state.timeLineDetail = response.data.statusProcess;
          // 状态 2，4，5 单独判断
          let specialArr = [2, 4, 5],
            specailIndex = specialArr.findIndex(t => t == state.timeLineDetail.txt_compensation_status);

          // 不包括
          if (specailIndex == -1) {
            let resultIndex = state.baseLine.findIndex(t => t.value == state.timeLineDetail.txt_compensation_status);
            state.timeLine = state.baseLine[resultIndex].text;
          } else {
            // 去掉 特殊值 再做判断
            state.baseLine.splice(0, 6);
            let valueArr = state.baseLine.filter(t => t.value.includes(state.timeLineDetail.txt_compensation_status));
            if (valueArr.length > 1) {
              if (state.timeLineDetail.txt_cra_verify_status == null) {
                let index = valueArr.findIndex(t => t.value == state.timeLineDetail.txt_compensation_status);
                state.timeLine = valueArr[index].text;
              } else {
                let value = "6" + state.timeLineDetail.txt_compensation_status.toString(),
                  index = valueArr.findIndex(t => t.value == value);
                state.timeLine = valueArr[index].text;
              }
            } else {
              state.timeLine = valueArr[0].text;
            }
          }
        }
      })
    }

    // 获取详情
    const getAuditDetail = () => {
      state.loading = true;
      getCostDetail({
        t_compensation_application_id: prop.dialogData.application_id
      }).then(response => {
        if (response.code == 200) {
          state.compensationDetails = response.data.compensationDetails;
          state.expenseDetails = response.data.expenseDetails;
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(`${e}`);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 审核
    const audit = param => {
      let verifyRemarks = "";
      if (param == false) {
        ElMessageBox.prompt('请简述拒绝理由', '拒绝申请', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          verifyRemarks = value;
          goSubmit(verifyRemarks, param);
        }).catch(() => {})
      } else {
        goSubmit(verifyRemarks, param);
      }
    };

    // 调用审核提交接口
    const goSubmit = (param, type) => {
      state.loading = true;
      getSponsorVerify({
        t_verify_sponsor_admin_id: prop.dialogData.verify_sponsor_admin_id,
        txt_sponsor_verify_remarks: param || "",
        status: type ? 1 : 2,
        t_compensation_application_id: prop.dialogData.application_id
      }).then(response => {
        if (response.code == 200) {
          ElMessage.success("审核成功");
          close(1);
        } else {
          ElMessage.error("审核失败" + response.msg);
          close(1);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    const close = (type) => {
      ctx.emit("close", type);
    }

    return {
      ...toRefs(state),
      close,
      audit,
      getAuditDetail,
      goSubmit,
      judgeFormat,
      getFileName,
      viewImg,
      viewFile,
      getTimeLineData
    }
  }
}
</script>

<style lang="scss">
.apply-audit-dialog {
  width: 650px;
  height: calc(100% - 25vh) !important;

  .el-dialog .el-dialog__body {
    height: calc(100% - 115px);
    padding: 20px 20px;
  }

  .aad-title {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
    margin-top: 0;

    &.has-margin-top {
      margin-top: 10px;
    }

    &.has-pointer {
      cursor: pointer;
    }

    div {
      color: #c2c2c9;

      &.is-actived {
        color: #606266;
      }
    }

    span {
      display: inline-block;
      width: 2px;
      height: 18px;
      background-color: #e5e8f1;
      margin: 0 16px;
    }
  }

  .aad-base-info-table {
    .pics-part-title {
      height: 50px;
      font-size: 18px;
    }

    .tr-has-image {
      .el-image {
        height: 200px;
      }
    }
  }

  .cost-detail-list-table {
    &.has-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .el-timeline {
      padding: 0;
      width: 50%;
      margin-top: 14px;

      .el-timeline-item {
        .el-timeline-item__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .el-timeline-item__content {
            font-weight: bold;

            p {
              font-weight: 400;
            }
          }

          .el-timeline-item__timestamp {
            margin-top: 0;
          }
        }
      }
    }
  }

  .dialog-footer {
    .el-button--primary {
      color: #fff;
    }
  }
}
</style>
